import React from 'react'
import clsx from 'clsx'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import useRouter from '../../utils/use-router'

const CustomRadio = withStyles({
    root: {
        color: '#999999',
        '&$checked': {
            color: '#44A2FC'
        },
        '&:hover': {
            backgroundColor: 'inherit'
        }
    }
})(({children, ...props}) => {
    return (
        <div className={`role-item ${props.checked ? 'active' : 'inactive'}`} onClick={(evt) => {
            props.onClick(props)
        }}>
            <div className={`icon-${props.value}`}></div>
            <div className={`radio-row ${props.value}`}>
                <div className="custom-radio">
                    <input
                        readOnly
                        type="radio"
                        value={props.value}
                        checked={props.checked}
                    />
                    <div className="checkmark"></div>
                </div>
                <Box
                    flex="1"
                    className={`role-name ${props.checked ? 'active' : 'inactive'}`}
                >
                    {props.value}
                </Box>
            </div>
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    fontStyle: {
        color: '#9ee2ff'
    },
    midItem: {
        marginTop: '1rem',
        marginBottom: '6rem'
    },
    item: {
        flex: 1,
        display: 'flex',
        alignItems: 'center'
    },
    coverLeft: {
        background: 'linear-gradient(to bottom, #307AFF, 50%, #46cdff)',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    coverContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: '#fff'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        display: 'flex',
        minWidth: 700,
        minHeight: 500,
        maxHeight: 500,
        borderRadius: '10px',
        boxShadow: '0px 6px 18px 0px rgba(0,0,0,0.2)'
    },
    input: {
        maxWidth: '250px',
        minWidth: '250px',
        alignSelf: 'center'
    },
    grid: {
        margin: '0 !important'
    },
    button: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '17px',
        textTransform: 'none',
        height: '44px',
        width: '260px',
        '&:hover': {
            backgroundColor: '#82C2FF'
        },
        margin: theme.spacing(1),
        marginTop: '33px',
        backgroundColor: '#44a2fc',
        borderRadius: '30px'
    },
    radio: {
        padding: '0',
        fontSize: '14px',
        // display: 'flex',
        alignItems: 'center',
        paddingRight: '5px'
    }
}))

export default function IndexCard() {
    const classes = useStyles()
    const routerCtx = useRouter()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const queryParams = new URLSearchParams(window.location.search);
    const channelName = queryParams.get('ch');
    if(stateCtx.config.channelName == '') {
        window.location.href = '/?ch='+channelName;
    }

    const handleClick = async () => {
        
        if (stateCtx.config.username === ''){
            mutationCtx.toastError(`Nama tidak boleh kosong`) 
            return;
        }
        const min = 1;
        const max = 1000;
        let rand = 10000 + (Math.floor(Math.random() * (max - min + 1)) + min);
        if(stateCtx.config.uid > 0) {
            rand = stateCtx.config.uid;
        }

        const channelOk = stateCtx.config.channelName == '' ? channelName : stateCtx.config.channelName;
        console.log(channelOk);
        if (channelOk == ''){
            mutationCtx.toastError(`Kami sedang refresh halaman ini`)
            mutationCtx.updateConfig({username: stateCtx.config.username, channelName: channelOk});
            window.location.href = '/?ch='+channelOk;
            return;
        }
       
        const token = await fetch('https://mp-token.herokuapp.com/rtc/'+channelOk+'/audience/uid/'+rand+'?expiry=15000')
        .then(response => response.json())
        .then((tokens) => tokens.rtcToken)
        .catch(error => console.error(error));
            
        mutationCtx.updateConfig({token: token,uid: rand, username: stateCtx.config.username, channelName: channelOk});
        mutationCtx.startLoading();
        
        routerCtx.history.push({
            pathname: `/meeting/${channelOk}`
        });
    }

    const handleChange = (evt) => {
        const {value, checked} = evt
        console.log('value', evt)
        mutationCtx.updateConfig({
            host: value === 'host'
        })
    }

    return (
        <Box
            marginTop="114px"
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="column"
        >
            {/* <Link to="/setting" className="setting-btn"/> */}
            <div className="role-container">
                { (stateCtx.config.oh === 'open') ? <CustomRadio
                    className={classes.radio}
                    value="host"
                    checked={true}
                    onClick={handleChange}
                ></CustomRadio> : <CustomRadio
                className={classes.radio}
                value="audience"
                checked={true}
                onClick={handleChange}
            ></CustomRadio> }
            </div>
            <Box
                marginTop="92"
                flex="1"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <FormControl className={clsx(classes.input, classes.grid)}>
                    <InputLabel htmlFor="name">Masukan nama Anda</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        value={stateCtx.config.username}
                        onChange={(evt) => {
                            const PATTERN = /^[a-zA-Z0-9!#$%&()+\-:;<=.>?@[\]^_{}|~,\s]{1,64}$/
                            const value = PATTERN.test(evt.target.value)
                            if (value && evt.target.value.length < 64) {
                                mutationCtx.updateConfig({username: evt.target.value})
                            }
                        }}
                    />
                    <Input
                        id="channelName"
                        name="channelName"
                        type="hidden"
                        value={stateCtx.config.channelName == '' ? channelName : stateCtx.config.channelName}
                        onChange={(evt) => {
                            const PATTERN = /^[a-zA-Z0-9!#$%&()+\-:;<=.>?@[\]^_{}|~,\s]{1,64}$/
                            const value = PATTERN.test(evt.target.value)
                            if (value && evt.target.value.length < 64) {
                                mutationCtx.updateConfig({channelName: evt.target.value})
                            } else {
                                mutationCtx.updateConfig({channelName: ''})
                            }
                        }}
                    />
                </FormControl>
                <FormControl className={classes.grid}>
                    <Button
                        onClick={handleClick}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        // disabled={(posts != null) ? ((!posts.state)? (stateCtx.config.oh === 'open') ? false : true : false) : false}
                    >
                        Gabung Siaran Radio
                    </Button>
                </FormControl>
            </Box>
        </Box>
    )
}
