import React, {useEffect, useState, useMemo} from 'react'
import {useGlobalState, useGlobalMutation} from '../utils/container'
import useRouter from '../utils/use-router'
import RTCClient from '../rtc-client'
import Tooltip from '@material-ui/core/Tooltip'
import StreamPlayer from './meeting/stream-player'
import StreamMenu from './meeting/stream-menu'
import { useLocation } from "react-router-dom";
import db from '../firebase';
import { collection, query, where, onSnapshot, getDocs, updateDoc, doc, limit, addDoc } from 'firebase/firestore';
import { getDatabase, ref, onValue, push, onDisconnect, set, serverTimestamp } from "firebase/database";


const MeetingPage = () => {
    const routerCtx = useRouter()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    const location = useLocation();

    const [posts, setPosts] = useState(null);
    const [ids, setIds] = useState(null);
    
    useEffect(() => {
        if(posts !== null){
            const db = getDatabase();
                const myConnectionsRef = ref(db, `${stateCtx.config.channelName}/${posts[1].deviceId}/connections`);
    
                // stores the timestamp of my last disconnect (the last time I was seen online)
                const lastOnlineRef = ref(db, `${stateCtx.config.channelName}/${posts[1].deviceId}/lastOnline`);
    
                const connectedRef = ref(db, '.info/connected');
                onValue(connectedRef, (snap) => {
                if (snap.val() === true) {
                    // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
                    const con = push(myConnectionsRef);
    
                    // When I disconnect, remove this device
                    onDisconnect(con).remove();
    
                    // Add this device to my connections list
                    // this value could contain info about the device or a timestamp too
                    set(con, true);
    
                    // When I disconnect, update the last time I was seen online
                    onDisconnect(lastOnlineRef).set(serverTimestamp());
                }
                });
        }
       
    });
    //     return () => {
    //         unsuscribe();
    //     }
    // }, [messages]);

    // useEffect(async () => {
       
    //     const db = getDatabase();
    //         const myConnectionsRef = ref(db, `${stateCtx.config.channelName}/${config.username}/connections`);

    //         // stores the timestamp of my last disconnect (the last time I was seen online)
    //         const lastOnlineRef = ref(db, `users/${stateCtx.config.channelName}/${config.username}/lastOnline`);

    //         const connectedRef = ref(db, '.info/connected');
    //         onValue(connectedRef, (snap) => {
    //         if (snap.val() === true) {
    //             // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
    //             const con = push(myConnectionsRef);

    //             // When I disconnect, remove this device
    //             onDisconnect(con).remove();

    //             // Add this device to my connections list
    //             // this value could contain info about the device or a timestamp too
    //             set(con, true);

    //             // When I disconnect, update the last time I was seen online
    //             onDisconnect(lastOnlineRef).set(serverTimestamp());
    //         }
    //         });

    //       }, []);

    const onUserPublished = (remoteUser, mediaType) => {
        // remoteUser:
        // mediaType: "audio" | "video" | "all"
        localClient.subscribe(remoteUser, mediaType)
            .then(mRemoteTrack => {
                mRemoteTrack.play()
                addRemoteUser(remoteUser)
            })
            .catch(err => {
                mutationCtx.toastError(
                    `stream ${remoteUser.getId()} subscribe failed: ${err}`
                )
            })

        if (mediaType === 'video' || mediaType === 'all') {

        }

        if (mediaType === 'audio') {
        }
    }

    const onUserUnPublished = (remoteUser, mediaType) => {
        // remoteUser:
        // mediaType: "audio" | "video" | "all"
        console.debug(`onUserUnPublished ${remoteUser.uid}`)
        removeRemoteUser(remoteUser)
        if (mediaType === 'video' || mediaType === 'all') {

        }

        if (mediaType === 'audio' || mediaType === 'all') {

        }
    }

    const localClient = useMemo(() => {
        const client = new RTCClient()
        client.createClient({codec: stateCtx.codec, mode: stateCtx.mode})

        client.on('connection-state-change', mutationCtx.connectionStateChanged)
        client.on('user-published', onUserPublished)
        client.on('user-unpublished', onUserUnPublished)

        return client
    }, [stateCtx.codec, stateCtx.mode])

    const [muteVideo, setMuteVideo] = useState(stateCtx.muteVideo)
    const [muteAudio, setMuteAudio] = useState(stateCtx.muteAudio)
    const [isShareScreen, setShareScreen] = useState(false)
    const [VideoTrack, setVideoTrack] = useState(null)
    const [AudioTrack, setAudioTrack] = useState(null)
    const [remoteUsers, setRemoteUsers] = useState({})

    const addRemoteUser = (remoteUser) => {
        remoteUsers[remoteUser.uid] = remoteUser
        setRemoteUsers(remoteUsers)
    }

    const removeRemoteUser = (remoteUser) => {
        delete remoteUsers[remoteUser.uid]
        setRemoteUsers(remoteUsers)
    }

    const config = useMemo(() => {
        return {
            token: stateCtx.config.token,
            channel: stateCtx.config.channelName,
            microphoneId: stateCtx.config.microphoneId,
            cameraId: stateCtx.config.cameraId,
            uid: stateCtx.config.uid,
            host: false,
            username: stateCtx.config.username
        }
    }, [stateCtx, muteVideo, muteAudio])

    const history = routerCtx.history

    const params = new URLSearchParams(window.location.search)

    // useEffect(() => {
    //     const roleParams = params.get('role')
    //     if (!config.channel && roleParams !== 'audience') {
    //         history.push('/')
    //     }
    // }, [config.channel, history, params])

    useEffect(() => {
        if (
            config.channel &&
            localClient._created &&
            localClient._joined == false &&
            localClient._leave == false
        ) {
            localClient.setClientRole(config.host ? 'host' : 'audience')
            localClient.getDevices().then(async (devices) => {
                console.log("first device id", devices);
                setPosts(devices);
                const citiesRef = collection(db, 'radio_room');
                const userAgent = window.navigator.userAgent; // user agent string
                const platform = window.navigator.platform; // platform (e.g. "Win32")
                const q = query(collection(citiesRef, config.channel, "devices"), where("device_id", "==", devices[1].deviceId), limit(1));
                const cities = [];
                const querySnapshot = await getDocs(q);
                if(!querySnapshot.empty){
                
                    querySnapshot.forEach(async (docu) => {
                        // doc.data() is never undefined for query doc snapshots
                        // console.log(doc.id, " => ", doc.data());
                        cities.push(docu.data());
                        setIds(docu.id);
                        const dataUser = { 
                            device_id: devices[1].deviceId,
                            device_name:userAgent,
                            is_host:0,
                            live:1,
                            os_name:platform,
                            os_version:"",
                            username:config.username
                        };
                        if(cities.length == 0){
                            await addDoc(collection(citiesRef, config.channel, "devices"), dataUser);
                        } else {
                            if(docu.id != null){
                                const id = docu.id;
                                await updateDoc(doc(citiesRef, config.channel, "devices/" + id), dataUser);
                            }
                        }
                    });
                }else{
                    const dataUser = { 
                        device_id: devices[1].deviceId,
                        device_name:userAgent,
                        is_host:0,
                        live:1,
                        os_name:platform,
                        os_version:"",
                        username:config.username
                    };
                    const cb = await addDoc(collection(citiesRef, config.channel, "devices"), dataUser);
                    setIds(cb.id);
                    
                }

                
                    
                
                    // const dataUser = { 
                    //     device_id: devices[1].deviceId,
                    //     device_name:userAgent,
                    //     is_host:0,
                    //     live:1,
                    //     os_name:platform,
                    //     os_version:"",
                    //     username:config.username
                    // };
                    
                    // await addDoc(collection(citiesRef, config.channel, "devices"), dataUser);
                
                
                // const docSnap = await getDoc(q);
                // if (docSnap.exists()) {
                // // Convert to City object
                // const city = docSnap.data();
                // // Use a City instance method
                // console.log(city.toString());
                // } else {
                // console.log("No such document!");
                // }
            //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
            //         querySnapshot.forEach(async(docu) => {
                        // cities.push(docu.data());
                        // setIds(docu.id);
                        // const dataUser = { 
                        //     device_id: devices[1].deviceId,
                        //     device_name:userAgent,
                        //     is_host:0,
                        //     live:1,
                        //     os_name:platform,
                        //     os_version:"",
                        //     username:config.username
                        // };
                        // if(cities.length == 0){
                        //     await addDoc(collection(citiesRef, config.channel, "devices"), dataUser);
                        // } else {
                        //     if(docu.id != null){
                        //         const id = docu.id;
                        //         await updateDoc(doc(citiesRef, config.channel, "devices/" + id), dataUser);
                        //     }
                        // }
            //         });

                    
                   
            // });
            
            
            }).catch(e => {
             console.log("get devices error!", e);
            });
                
            localClient.join(config.channel, config.token, config.uid)
                .then((uid) => {
                    config.uid = uid

                    if (config.host) {
                        localClient.startLive(config.microphoneId, config.cameraId)
                            .then(() => {
                                setVideoTrack(localClient.mLocalVideoTrack)
                                setAudioTrack(localClient.mLocalAudioTrack)
                            })
                    }
                    mutationCtx.stopLoading()
                })
                .catch((err) => {
                    mutationCtx.toastError(`Mohon maaf siaran Anda sudah tidak tersedia`) 
                    routerCtx.history.push('/')
                })
        }
    }, [localClient, mutationCtx, config, routerCtx])

    const toggleVideo = () => {
        const newValue = !muteVideo
        if (newValue) {
            localClient._client.unpublish(VideoTrack)
        } else {
            localClient._client.publish(VideoTrack)
        }
        setMuteVideo(newValue)
    }

    const toggleAudio = () => {
        const newValue = !muteAudio
        if (newValue) {
            localClient._client.unpublish(AudioTrack)
        } else {
            localClient._client.publish(AudioTrack)
        }
        setMuteAudio(newValue)
    }

    const toggleShareScreen = () => {
        const newValue = !isShareScreen
        if (newValue) {
            setShareScreen(newValue)

            setMuteVideo(false)
            setMuteAudio(false)

            localClient.stopLive()
            localClient.startShareScrren()
                .then(() => {
                    setVideoTrack(localClient.mLocalVideoTrack)
                    setAudioTrack(localClient.mLocalAudioTrack)
                })
                .catch((err) => {
                    mutationCtx.toastError(`屏幕分享失败 code= ${err.code}`)
                })
        } else {
            localClient.stopShareScrren()
            localClient.startLive(config.microphoneId, config.cameraId)
                .then(() => {
                    setShareScreen(newValue)

                    setVideoTrack(localClient.mLocalVideoTrack)
                    setAudioTrack(localClient.mLocalAudioTrack)
                })
        }
    }

    const doLeave = () => {
        localClient.leave().then( async() => {
            console.log(ids);
            
            const citiesRef = collection(db, 'radio_room');
            const userAgent = window.navigator.userAgent; // user agent string
            const platform = window.navigator.platform; // platform (e.g. "Win32")
            const dataUser = { 
                device_id: posts[1].deviceId,
                device_name:userAgent,
                is_host:0,
                live:0,
                os_name:platform,
                os_version:"",
                username:config.username
            };
            await updateDoc(doc(citiesRef, config.channel, "devices/" + ids), dataUser);
            localClient.stopLive()
            localClient.leave()
        
            window.location.href = '/?ch='+config.channel;
        })
    }

    return (
        <div className="meeting">
            <div className="current-view">
                <div className="nav">
                    <div className="avatar-container">
                        <div className="default-avatar"></div>
                        <div className="avatar-text">Jamaah</div>
                        <div className="like"></div>
                    </div>
                    <Tooltip title="quit">
                        <div
                            className="quit"
                            onClick={doLeave}
                        ></div>
                    </Tooltip>
                </div>
                <StreamPlayer
                    uid={config.uid}
                    isLocal={true}
                    videoTrack={VideoTrack}
                    audioTrack={AudioTrack}
                    muteAudio={muteAudio}
                    muteVideo={muteVideo}
                    showInfo={stateCtx.profile}
                    rtcClient={localClient._client}
                >
                    {config.host ? <StreamMenu
                        muteAudio={muteAudio}
                        muteVideo={muteVideo}
                        shareScreen={isShareScreen}
                        toggleVideo={toggleVideo}
                        toggleAudio={toggleAudio}
                        toggleShareScreen={toggleShareScreen}
                    /> : null}
                </StreamPlayer>
                <div className="stream-container">
                    {Object.values(remoteUsers).map(remoteUser => {
                        return <StreamPlayer
                            key={`key-${remoteUser.uid}`}
                            uid={remoteUser.uid}
                            videoTrack={remoteUser.videoTrack}
                            audioTrack={remoteUser.audioTrack}
                            muteAudio={false}
                            muteVideo={false}
                            showInfo={false}
                            rtcClient={localClient._client}
                        />
                    })}
                </div>
                
            </div>
        </div>
    )
}

export default React.memo(MeetingPage)
